import { template as template_2c36bec121934b7a8ac77d2fd1f2eafb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2c36bec121934b7a8ac77d2fd1f2eafb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
