import { template as template_5541370c9e3b4fccbfc087f70e75bafe } from "@ember/template-compiler";
import { notEq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
const FKControlConditionalContentItem = template_5541370c9e3b4fccbfc087f70e75bafe(`
  <div
    class={{concatClass
      "form-kit__conditional-display-content"
      (if (notEq @name @activeName) "hidden")
    }}
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
