import { template as template_64cd9f75f0e14f59b46a8ac384e3c1c6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_64cd9f75f0e14f59b46a8ac384e3c1c6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
