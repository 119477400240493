import { template as template_3e6905100d7e40a9a16cb91fbcf14a33 } from "@ember/template-compiler";
const FKLabel = template_3e6905100d7e40a9a16cb91fbcf14a33(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
