import { template as template_fe34cc4cdc794be48b5fedca5e1d633e } from "@ember/template-compiler";
const WelcomeHeader = template_fe34cc4cdc794be48b5fedca5e1d633e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
