import { template as template_c93a464abb914585b167db9d9178b7ab } from "@ember/template-compiler";
const FKControlMenuContainer = template_c93a464abb914585b167db9d9178b7ab(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
