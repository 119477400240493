import { template as template_caf6ccd465e74e07b44c60b798ace938 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_caf6ccd465e74e07b44c60b798ace938(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
